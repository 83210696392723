import { Header } from '~/application/components/Header/Header'
import { Footer } from '~/application/components/Footer/Footer'
import { Container } from '~/application/components/Container/Container'
import { Content } from '~/application/components/Content/Content'

export function ApplicationError() {
  return (
    <>
      <Header loginUser={null} />
      <Content>
        <Container>
          <div>
            <p>エラーが発生しました。</p>
            <p>時間をおいてトップページから再度アクセスしてください。</p>
          </div>
        </Container>
      </Content>
      <Footer />
    </>
  )
}
