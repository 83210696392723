import { captureRemixErrorBoundaryError } from '@sentry/remix'
import {
  Links,
  LiveReload,
  Meta,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from '@remix-run/react'
import { NotFound } from '~/domain/error/components/NotFound/NotFound'
import { ApplicationError } from '~/domain/error/components/ApplicationError/ApplicationError'

export const ErrorBoundary = () => {
  const error = useRouteError() as {
    status: number
  }
  const title = (() => {
    if (error.status === 404) {
      return 'ページが見つかりません'
    }

    return 'エラー'
  })()

  captureRemixErrorBoundaryError(error)

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{title} | BeBlooming</title>
        <Meta />
        <Links />
      </head>
      <body>
        {error.status === 404 && <NotFound />}
        {error.status !== 404 && <ApplicationError />}
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  )
}
