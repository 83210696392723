import { cssBundleHref } from '@remix-run/css-bundle';
import type { LinksFunction } from '@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useMatches } from
'@remix-run/react';
import { typedjson, useTypedLoaderData } from 'remix-typedjson';
import { Toaster } from 'react-hot-toast';
import { withSentry } from '@sentry/remix';
import { useEffect } from 'react';
import * as Sentry from '@sentry/remix';
import * as gtag from '~/application/libs/gtags.client';
import { ErrorBoundary } from '~/domain/error/components/ErrorBoundary/ErrorBoundary';
import stylesheet from '~/globals.css';
import "react-datepicker/dist/react-datepicker.css?__remix_sideEffect__";

export const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
{ rel: 'stylesheet', href: stylesheet },
{
  rel: 'apple-touch-icon',
  sizes: '180x180',
  href: '/images/favicons/apple-touch-icon.png'
},
{
  rel: 'icon',
  sizes: '32x32',
  type: 'image/png',
  href: '/images/favicons/favicon-32x32.png'
},
{
  rel: 'icon',
  sizes: '16x16',
  type: 'image/png',
  href: '/images/favicons/favicon-16x16.png'
}];


export async function loader() {
  return typedjson({
    SENTRY_DSN: process.env.SENTRY_DSN,
    GA_ID: process.env.GA_ID
  });
}

function App() {
  const data = useTypedLoaderData<typeof loader>();
  const location = useLocation();

  useEffect(() => {
    if (data.SENTRY_DSN && process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: data.SENTRY_DSN,
        integrations: [
        Sentry.browserTracingIntegration({
          useEffect,
          useLocation,
          useMatches
        })],

        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost', /^https:\/\/bebloomming\.jp/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
      });
    }
  }, [data.SENTRY_DSN]);

  useEffect(() => {
    if (data.GA_ID) {
      gtag.pageview(location.pathname, data.GA_ID);
    }
  }, [data.GA_ID]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${data.GA_ID}`} />

        <script
          async
          id="gtag-init"
          dangerouslySetInnerHTML={{
            __html: `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${data.GA_ID}', {
page_path: window.location.pathname,
});
`
          }} />

      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
        <Toaster />
      </body>
    </html>);

}

export { ErrorBoundary };

export default withSentry(App);